@charset "utf-8";
/* ---------------------------------------------------------
   base.css
----------------------------------------------------------*/
@media #{$scr_sp} {

}
@media #{$scr_pc} {

}
@media #{$scr_retina} {

}
// transform: translateX(-50%);
/* ---------------------------------------------------------
default・option
----------------------------------------------------------*/
html {
  font-size: 62.5%;
  width: 100vw;
  overflow-x: hidden;
}
body {
  width: 100vw;
  font-size: 1.6rem;
  font-family: $font-family-yugothic;
  line-height: 1.3;
  background: $color_black;
  color: #fff;
  word-break: break-all;
  overflow-x: hidden;
  @media #{$scr_pc} {
    padding-top: 164px;
  }
  @media #{$scr_sp} {
    font-size: 1.4rem;
  }
  &.scroll{
    @media #{$scr_pc} {
      // padding-top: 62px;
    }
    @media #{$scr_sp} {
      padding-bottom: 40px;
    }
  }
  &.scroll.assess {
    @media #{$scr_sp} {
      padding-bottom: 0;
    }
  }
  &.open  {
    overflow: hidden;
  }
}
a {
  color:$color_link_text;
  &:hover {
    text-decoration: none;
  }
}
img {
  vertical-align: bottom;
}
.sp-only {
  display: none !important ;
  @media #{$scr_sp} {
    display: block !important;
  }
}
.sp-none {
  @media #{$scr_sp} {
    display: none !important;
  }
}
.pc-only {
  display: none !important;
  @media #{$scr_pc} {
    display: block !important;
  }
}
.pc-none {
  @media #{$scr_pc} {
    display: none !important;
  }
}
.color_brown {
  color: $color_brown;
}
.newtab {
  vertical-align: middle;
  margin-left: 5px;
}
.bg_gray {
  background:$color_bg_g;
}
.en {
  font-weight: normal;
}
/* ---------------------------------------------------------
header
----------------------------------------------------------*/
.header {
  @media #{$scr_pc} {
    position: fixed;
    width: 100%;
    top:0;
    left: 0;
    z-index: 9999;
    background: $color_black;
    // animation: headerFixed .5s linear forwards;
  }
}
.header_top {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  @media #{$scr_sp} {
    height: 64px;
    padding: 0 15px;
    align-items: center;
  }
}
.header_top_logo {
  @media #{$scr_sp} {
    max-width: 264px;
    width: 80%;
  }
  img {
    @media #{$scr_sp} {
      width: 100%;
      height: auto;
    }
  }
}
.header_top_nav {
  font-size: 1.2rem;
  @media #{$scr_sp} {
    display: none;
  }
  a{
    color: #fff;
    text-decoration: none;
  }
  .nav_conversion {
    display: flex;
    justify-content: flex-end;
    li {
      margin-left: 10px;
      &:nth-child(1){
        a {
          &:hover {
            &:before {
              background-image: url("../img/common_icon_tel_b.png");
            }
          }
          &:before {
            vertical-align: -5px;
          }
        }
      }
      &:nth-child(2){
        a {
          &:hover {
            &:before {
              background-image: url("../img/common_icon_mail_b.png");
            }
          }
          &:before {
            width: 13.45px;
            height: 8.95px;
            background-image: url("../img/common_icon_mail.png");
            vertical-align: -5px;
          }
        }
      }
      &:nth-child(3){
        a {
          &:hover {
            &:before {
              background-image: url("../img/common_icon_line_b.png");
            }
          }
          &:before {
            width: 15.43px;
            height: 13.67px;
            background-image: url("../img/common_icon_line.png");
            vertical-align: -9px;
          }
        }
      }
      &:nth-child(n+4){
        a {
          background: none;
          border: 1px solid #fff;
          &:hover {
            background: #fff;
            color:$color_black;
          }
          &:before {
            display: none;
          }
        }
      }
    }
    a {
      position: relative;
      display: block;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      background: $color_blue;
      color:#fff;
      border-radius: 15px;
      box-sizing:border-box;
      vertical-align: middle;
      &:hover {
        background: #fff;
        color:$color_black;
      }
      &:before {
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        width: 15.36px;
        height: 9.54px;
        background-image: url("../img/common_icon_tel.png");
        background-position: center;
        background-size: 100%;
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }
  .nav_link {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    > li {
      padding: 0 5px;
      // border-left: 1px solid #fff;
      &:last-child {
        // border-right: 1px solid #fff;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .drop_wrapper {
      position: relative;
      @media #{$scr_sp} {
        position: static;
      }
      &:hover {
        .drop_content {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .drop_content {
      position: absolute;
      left:50%;
      z-index: 8000;
      padding-top: 10px;
      transform: translateX(-50%);
      text-align: center;
      opacity: 0;
      visibility: hidden;
      li {
        background: #fff;
        &:not(:last-child){
          border-bottom: 1px solid $color_border_light;
        }
      }
      a {
        display: block;
        padding: 10px;
        white-space: nowrap;
        color: $color_black;
        &:hover {
          color: $color_link_text;
          text-decoration: none;
        }
      }
    }
  }
}
.header_sp_menu {
  @media #{$scr_pc} {
    display: none;
  }
  .menu_btn {
    position: fixed;
    top:18px;
    right: 10px;
    z-index: 9999;
    transition : ease all .5s;
    .scroll & , .open & {
      // position: fixed;
      // top:-20px;
      // animation: btnFix 2s ease 0s 1 normal forwards;
    }
    .text {
      margin-top: 3px;
      font-size: 1.0rem;
      transform:scale(0.7);
      transform-origin:left top;
      .open & {
        opacity: 0;
        visibility: hidden;
      }
    }
    .line,span {
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box;
    }
    .line {
      position: relative;
      width: 20px;
      height: 18px;
    }
    span {
      position: absolute;
      right: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      &:nth-of-type(1) {
        // width: 20px;
        top: 0;
        .open & {
          width: 100%;
          transform: translateY(8px) rotate(-45deg);
          background-color: #fff;
        }
      }
      &:nth-of-type(2) {
        top: 8px;
        .open & {
          opacity: 0;
        }
      }
      &:nth-of-type(3) {
        // width: 20px;
        bottom: 0;
        .open & {
          width: 100%;
          transform: translateY(-8px) rotate(45deg);
          background-color: #fff;
        }
      }
    }
  }
  .menu_content {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    overflow: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 5%;
    background: $color_blue;
    opacity: 0;
    visibility: hidden;
    transition:ease all .5s;
    .open & {
      opacity: 1;
      visibility: visible;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .menu_content_logo {
    margin: 20px 0 30px;
    text-align: center;
  }
  .menu_content_list {
    border-top:1px solid rgba(#fff,0.66);
    > li {
      border-bottom:1px solid rgba(#fff,0.66);
      a , .drop_btn {
        display: block;
        padding: 18px 10px;
      }
    }
  }
  .drop_btn {
    position: relative;
    &.open {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 10px;
      top:50%;
      transform: translateY(-50%) rotate(90deg);
      width: 10.12px ;
      height: 5.06px;
      background: url("../img/common_icon_arrow.png");
      background-size: cover;
      transition:all ease .5s;
    }
  }
  .drop_content {
    display: none;
    padding: 10px 0;
    border-top:1px solid rgba(#fff,0.66);
    li {
      a {
        padding: 10px 10px;
      }
    }
    &.open {
    }
  }
  .menu_content_conversion {
    margin-top: 30px;
    text-align: center;
    font-weight: bold;
    li {
      margin: 0 10px;
      &:not(:last-child){
        margin-bottom: 20px;
      }
      &:nth-child(1){
        a {
          &:before {
            vertical-align: -8px;
          }
        }
      }
      &:nth-child(2){
        a {
          &:before {
            width: 18.95px;
            height: 12.61px;
            background-image: url("../img/common_icon_mail_bl.png");
            vertical-align: -6px;
          }
        }
      }
      &:nth-child(3){
        a {
          &:before {
            width: 24px;
            height: 21.27px;
            background-image: url("../img/common_icon_line_bl.png");
            vertical-align: -14px;
          }
        }
      }
      &:nth-child(n+4){
        margin-top: 30px;
        a {
          display: inline-block;
          width: 50%;
          background: none;
          color: #fff;
          border: 1px solid #fff;
          font-size: 1.4rem;
          &:before {
            display: none;
          }
        }
      }
    }
    a {
      position: relative;
      display: block;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      background: #fff;
      color:$color_blue;
      border-radius: 25px;
      box-sizing:border-box;
      vertical-align: middle;
      text-align: center;
      &:hover {
        background: #fff;
        color:$color_black;
      }
      &:before {
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        width: 22px;
        height: 14.18px;
        background-image: url("../img/common_icon_tel_bl.png");
        background-position: center;
        background-size: 100%;
        vertical-align: middle;
        margin-right: 3px;
      }
    }
  }
  .menu_content_sns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    li {
      margin: 0 5px;
      a {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        background: #fff;
        transition:all ease .5s;
        &:hover {
          transform:scale(1.1);
        }
        img {
          vertical-align: middle;
        }
      }
    }
  }
  .menu_close {
    margin: 30px 0 10px;
    text-align: center;
    font-size: 1.4rem;
    img {
      margin-right: 5px;

    }
  }
}
.header_bottom {
  font-size: 1.5rem;
  text-align: center;
  border-top:1px solid $color_border;
  border-bottom:1px solid $color_border;
  @media #{$scr_sp} {
    position: relative;
    font-size: 1.3rem;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  > ul {
    display: flex;
    justify-content: space-around;
    > li {
      width: 25%;
      &:not(:last-child){
        border-right: 1px solid $color_border;
      }
      a , .drop_text {
        display: block;
        padding: 13px 0;
        background: $color_bg_g;
        transition:ease all .1s;
        @media #{$scr_sp} {
          padding: 10px 0;
        }
        &:hover{
          @media #{$scr_pc} {
            background: #fff;
          }
          .ja {
            @media #{$scr_pc} {
              color: $color_black;
            }
          }
        }
        &.active {
          background: #fff;
        .ja {
            color: $color_black;
          }
        }
      }
    }
  }
  .ja , .en {
    display: block;
  }
  .ja {
  }
  .en {
    margin-top: 3px;
    font-size: 0.9rem;
    color:$color_blue;
    @media #{$scr_sp} {
      margin-top: 2px;
      transform:scale(0.8);
    }
  }
  .drop_wrapper {
    position: relative;
    @media #{$scr_sp} {
      position: static;
    }
    &:hover {
      .drop_text {
        @media #{$scr_pc} {
          background: $color_blue;
        }
        .ja {
          @media #{$scr_pc} {
            color: #fff;
          }
        }
        .en {
          @media #{$scr_pc} {
            color: #fff;
          }
        }
      }
      .drop_content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .drop_content {
    position: absolute;
    left:0;
    top:100%;
    z-index: 8000;
    width: 100%;
    font-size: 1.3rem;
    transition:ease all .1s;
    opacity: 0;
    visibility: hidden;
    @media #{$scr_sp} {
      width: 100vw;
    }
    @media #{$scr_pc} {

    }
    li {
      border-top: 1px solid $color_border;
    }
    a {
      @media #{$scr_sp} {
        padding: 20px 0;
      }
      &:hover {
        color:$color_link_text;
      }
    }
  }
  .drop_text {
    &.open {
      @media #{$scr_sp} {
        background: $color_blue;
      }
      .ja {
        @media #{$scr_sp} {
          color: #fff;
        }
      }
      .en {
        @media #{$scr_sp} {
          color: #fff;
        }
      }
    }
  }
}
@keyframes headerFixed {
  0% {
    top:-62px;
  }
  100% {
    top:0px;
  }
}

/* ---------------------------------------------------------
サイド固定エリア
----------------------------------------------------------*/
.side_fixed {
  position: fixed;
  right: 20px;
  top:50%;
  z-index: 8000;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition:all ease .5s;
  .scroll & {
    opacity: 1;
    visibility: visible;
  }
  .scroll.assess & {
    @media #{$scr_sp} {
      bottom:20px;
    }
  }
  @media #{$scr_sp} {
    top:auto;
    bottom:50px;
    right: 10px;
    transform: translateY(0);
  }
  ul {
    display: flex;
    flex-direction: column;
    @media #{$scr_sp} {
      // display: block;
      // justify-content: flex-end;
    }
    li {
      &:not(:last-child){
        margin-bottom: 10px;
        @media #{$scr_sp} {
          display: none;
        }
      }
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background: $color_link;
    transition:all ease .5s;
    &:hover {
      transform:scale(1.1);
    }
    img {
      vertical-align: middle;
    }
  }
}

/* ---------------------------------------------------------
フッターエリア
----------------------------------------------------------*/
.footer_fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 8000;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition:ease all .5s;
  // border-top: 1px solid $color_black;
  @media #{$scr_pc} {
    display: none;
  }
  .scroll & {
    opacity: 1;
    visibility: visible;
  }
  .assess.scroll & {
    opacity: 0;
    visibility: hidden;
  }
  ul {
    display: flex;
    font-size: 1.1rem;
    li {
      width: 100%;
      // flex-basis: auto;
      &:nth-child(1){
        a {
          &:hover {
            &:before {
              background-image: url("../img/common_icon_tel_b.png");
            }
          }
          &:before {
            vertical-align: -5px;
          }
        }
      }
      &:nth-child(2){
        // margin: 0 1px;
        border-left: 1px solid #428fcc;
        border-right: 1px solid #428fcc;
        a {
          &:hover {
            &:before {
              background-image: url("../img/common_icon_mail_b.png");
            }
          }
          &:before {
            width: 13.45px;
            height: 8.95px;
            background-image: url("../img/common_icon_mail.png");
            vertical-align: -5px;
          }
        }
      }
      &:nth-child(3){
        a {
          &:hover {
            &:before {
              background-image: url("../img/common_icon_line_b.png");
            }
          }
          &:before {
            width: 15.43px;
            height: 14px;
            background-image: url("../img/common_icon_line.png");
            vertical-align: -9px;
          }
        }
      }
    }
    a {
      width: 100%;
      height: 40px;
      line-height: 40px;
      position: relative;
      display: block;
      padding: 0 7px;
      background: $color_blue;
      color:#fff;
      text-decoration: none;
      box-sizing:border-box;
      text-align: center;
      white-space: nowrap;
      &:hover {
        background: #fff;
        color:$color_black;
      }
      &:before {
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        width: 15.36px;
        height: 9.54px;
        background-image: url("../img/common_icon_tel.png");
        background-position: center;
        background-size: cover;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}





/* ---------------------------------------------------------
footer
----------------------------------------------------------*/
.footer {

}
.footer_wrapper {
  position: relative;
  padding: 50px 0 10px;
  background-size: cover;
  background-position: center;
  background-image: url("../img/common_footer_bg.jpg");
  z-index: 0;
  @media #{$scr_retina} {
    background-image: url("../img/common_footer_bg_2x.jpg");
  }
  @media #{$scr_sp} {
    padding: 40px 5% 10px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: rgba($color_black,0.8);
    z-index: -1;
  }
}
.footer_inner {
  max-width: 1000px;
  margin: 0 auto;
}
.foter_top {
  text-align: center;
}
.foter_logo {
  margin-bottom: 35px;
  @media #{$scr_sp} {
    width: 80%;
    margin: 0 auto 30px;
  }
  img {
    @media #{$scr_sp} {
      width: 100%;
      height: auto;
    }
    // vertical-align: bottom;
  }
}
.foter_logo_under {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  line-height: 1.3;
  @media #{$scr_sp} {
    flex-direction: column;
  }
  li {
    margin: 0 5px;
    @media #{$scr_sp} {
      margin: 0 0 5px 0;
    }
  }
}
.foter_btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  font-size: 1.4rem;
  li {
    margin: 0 10px;
    @media #{$scr_sp} {
      margin: 0 0 20px 0;
    }
    &:nth-child(1){
      a {
        &:hover {
          &:before {
            background-image: url("../img/common_icon_tel_b.png");
          }
        }
        &:before {
          vertical-align: -5px;
        }
      }
    }
    &:nth-child(2){
      a {
        &:hover {
          &:before {
            background-image: url("../img/common_icon_mail_b.png");
          }
        }
        &:before {
          width: 13.45px;
          height: 8.95px;
          background-image: url("../img/common_icon_mail.png");
          vertical-align: -5px;
        }
      }
    }
    &:nth-child(3){
      a {
        &:hover {
          &:before {
            background-image: url("../img/common_icon_line_b.png");
          }
        }
        &:before {
          width: 15.43px;
          height: 13.67px;
          background-image: url("../img/common_icon_line.png");
          vertical-align: -9px;
        }
      }
    }
  }
  a {
    position: relative;
    display: block;
    width: 200px;
    height: 34px;
    line-height: 34px;
    padding: 0 20px;
    background: $color_blue;
    color:#fff;
    border-radius: 17px;
    text-decoration: none;
    box-sizing:border-box;
    &:hover {
      background: #fff;
      color:$color_black;
    }
    &:before {
      transform: translateY(-50%);
      content: "";
      display: inline-block;
      width: 15.36px;
      height: 9.54px;
      background-image: url("../img/common_icon_tel.png");
      background-position: center;
      background-size: 100%;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
.footer_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  font-size: 1.2rem;
  @media #{$scr_sp} {
    flex-wrap: wrap;
    margin-top: 20px;
    font-size: 1.0rem;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .bottom_block {
    width: 220px;
    padding-top: 20px;
    border-top: 1px solid $color_text_light02;
    @media #{$scr_sp} {
      width: calc(50% - 2.5%);
    }
    ul {
      margin-bottom: 30px;
    }
    li {
      margin-bottom: 10px;
    }
    .keisen {
      position: relative;
      &:before {
        content: "└";
      }
    }
    .keisen2 {
      position: relative;
      &:before {
        content: "　└";
      }
    }
  }
}
.footer_copy {
  padding: 20px 0;
  background: $color_black;
  color: #fff;
  font-size: 1.0rem;
  text-align: center;
  border-top: 1px solid $color_border;
}
/* ---------------------------------------------------------
main
----------------------------------------------------------*/
.main {
  padding: 10px 0 100px 0;
  letter-spacing: 0.1em;
  .bukken & {
    letter-spacing: 0;
  }
  @media #{$scr_sp} {
    padding: 10px 5% 20%;
  }
  .is_home & {
    padding-top: 0;
    @media #{$scr_sp} {
      padding: 0;
    }
  }
  .assess & {
    @media #{$scr_sp} {
      padding: 10px 0 20%;
    }
  }
  .service & {
    @media #{$scr_sp} {
      padding: 10px 0 20%;
    }
  }
  .english & {
    @media #{$scr_sp} {
      padding: 10px 0 20%;
    }
  }
}
/* ---------------------------------------------------------
wrapper
----------------------------------------------------------*/
.wrapper {
  max-width: 1000px;
  margin: 0 auto;
  .assess & {
    @media #{$scr_sp} {
      padding: 0 5%;
    }
  }
  .is_home & {
    @media #{$scr_pc} {
      max-width: calc(1000/1240*100%);
    }
  }
  .news_details & {
    max-width: 700px;
  }
}
/* ---------------------------------------------------------
content
----------------------------------------------------------*/
.content {
  line-height: 1.8;
}
/* ---------------------------------------------------------
パンクズ
----------------------------------------------------------*/
.breadcrumbs {
  margin: 0 0 50px;
  padding: 0;
  font-size: 1.0rem;
  color:$color_text_light02;
  letter-spacing: 0;
  // border-bottom:1px solid $color_border;
  @media #{$scr_sp} {
    margin-bottom: 10%;
    // padding: 5%;
  }
  .details & {
    @media #{$scr_pc} {
      margin-bottom: 20px;
    }
  }
  a {
    color:$color_text_light02;
    text-decoration: none;
    &:hover {
      color:$color_link_text;
    }
  }
}
/* ---------------------------------------------------------
ページタイトル
----------------------------------------------------------*/
.mv {
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../img/common_mv.jpg");
  border-bottom: 1px solid $color_border;
  @media #{$scr_sp} {
    height: 200px;
  }
  .bukken & {
    background-image: url("../img/rent_mv.jpg");
    height: 100px;
  }
  .bukken.rent.luxury & {
    background-image: url("../img/rent_luxury_mv.jpg");
  }
  .bukken.rent.recommend & {
    background-image: url("../img/rent_recommend_mv.jpg");
  }
  .bukken.buy.all & {
    background-image: url("../img/buy_mv.jpg");
  }
  .bukken.buy.luxury & {
    background-image: url("../img/buy_luxury_mv.jpg");
  }
  .bukken.buy.recommend & {
    background-image: url("../img/buy_recommend_mv.jpg");
  }
  .event & {
    background-image: url("../img/event_mv.jpg");
  }
  .news & {
    background-image: url("../img/news_mv.jpg");
  }
  .knowledge & {
    background-image: url("../img/knowledge_mv.jpg");
  }
  .voice & {
    background-image: url("../img/voice_mv.jpg");
  }
  .assess & {
    background-image: url("../img/assess_mv.jpg");
  }
  .about & {
    background-image: url("../img/about_mv.jpg");
  }
  .service & {
    background-image: url("../img/service_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/service_mv_sp.jpg");
    }
  }
  .access & {
    background-image: url("../img/access_mv.jpg");
  }
  .company & {
    background-image: url("../img/company_mv.jpg");
  }
  .english & {
    background-image: url("../img/english_mv.jpg");
  }
  .lease & {
    background-image: url("../img/lease_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/lease_mv_sp.jpg");
    }
  }
  .rent_flow & {
    background-image: url("../img/rent_flow_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/rent_flow_mv_sp.jpg");
    }
  }
  .member & {
    background-image: url("../img/member_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/member_mv_sp.jpg");
    }
  }
  .privacy & {
    background-image: url("../img/privacy_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/privacy_mv_sp.jpg");
    }
  }
  .faq & {
    background-image: url("../img/faq_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/faq_mv_sp.jpg");
    }
  }
  .reform &,
  .post-type-archive-reform_property &,
  .single-reform_property & {
    background-image: url("../img/reform_mv.jpg");
    @media #{$scr_sp} {
      background-image: url("../img/reform_mv_sp.jpg");
    }
  }
  &:before {
    // position: absolute;
    // left: 0;
    // top:0;
    // z-index: 0;
    // display: block;
    // content: "";
    // width: 1240px;
    // height: 300px;
    // background-image: url("../img/common_mv_bg.png");
    // background-position: left top;
    // background-repeat: no-repeat;
    // background-size: cover;
    @media #{$scr_sp} {
      // width: 100%;
      // height: 100%;
      // background-image: url("../img/common_mv_bg_sp.png");
    }
  }
}
.page_title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;
  margin: 0;
  text-align: left;
  font-weight: normal;
  @media #{$scr_pc} {
    width: 1000px;
    margin: 0 auto;
  }
  @media #{$scr_sp} {
    padding: 0 5%;
    // margin: 0 0 0 5%;
  }
  .bukken & {
    font-size: 2.0rem;
  }
  &:before {
    display: block;
    position: absolute;
    right: 100%;
    top:0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba($color_light_blue2 , 0.8);
  }
  &:after {
    position: absolute;
    left: 0;
    top:0;
    z-index: -1;
    display: block;
    content: "";
    width: 1000px;
    height: 100%;
    background-image: url("../img/common_mv_bg.png");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    .rent & {
      @media #{$scr_pc} {
        background-image: url("../img/common_mv_bukken_bg.png");
      }
    }
    @media #{$scr_sp} {
      width: 100%;
      background-image: url("../img/common_mv_bg_sp.png");
    }
  }
  .en , .ja {
    display: block;
    letter-spacing: 0.2em;
    @media #{$scr_sp} {
      letter-spacing: 0.15em;
    }
  }
  .en {
    position: relative;
    font-size: 4.0rem;
    font-family: $font-family-en;
    font-weight: normal;
    .knowledge & {
      margin-bottom: 5px;
      line-height: 1.1;
    }
    @media #{$scr_sp} {
      font-size: 2.8rem;
      .knowledge & {
        font-size: 2.2rem;
      }
    }
  }
  .ja {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
  }
  .min {
    font-size: 1.4rem;
  }
}
.section_title {
  margin: 90px 0 50px;
  text-align: center;
  @media #{$scr_sp} {
    margin-top: 35px;
    margin-bottom: 40px;
  }
  .ja ,.en {
    position: relative;
    display: block;
    letter-spacing: 0.2em;
    @media #{$scr_sp} {
      letter-spacing: 0.15em;
    }
  }
  .ja {
    font-weight: bold;
    font-size: 2.8rem;
    @media #{$scr_sp} {
      font-size: 1.8rem;
    }
  }
  .en {
    display: inline-block;
    font-weight: normal;
    font-family: $font-family-en;
    font-size: 2.2rem;
    text-transform: uppercase;
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
    &:before , &:after {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      background: #fff;
    }
    &:before  {
      right: calc(100% + 10px);
    }
    &:after {
      left: calc(100% + 10px);
    }
  }
}
.section_title_ja {
  margin: 80px 0;
  text-align: center;
  line-height: 1.3;
  @media #{$scr_sp} {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .ja ,.en {
    position: relative;
    display: block;
    letter-spacing: 0.2em;
    @media #{$scr_sp} {
      letter-spacing: 0.15em;
    }
  }
  .ja {
    font-weight: bold;
    font-size: 2.8rem;
    @media #{$scr_sp} {
      font-size: 1.8rem;
    }
  }
  .en {
    display: inline-block;
    font-weight: normal;
    font-family: $font-family-en;
    font-size: 1.4rem;
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
  }
}
.sub_title {
  margin: 0 0 70px;
  text-align: center;
  @media #{$scr_sp} {
    margin-top: 35px;
    margin-bottom: 40px;
  }
  .ja ,.en {
    display: block;
    letter-spacing: 0.2em;
    @media #{$scr_sp} {
      letter-spacing: 0.15em;
    }
  }
  .ja {
    font-weight: bold;
    font-size: 2.4rem;
    @media #{$scr_sp} {
      font-size: 1.8rem;
    }
  }
  .en {
    margin-top: 10px;
    font-weight: normal;
    font-family: $font-family-en;
    font-size: 1.4rem;
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
  }
}
.line_title {
  position: relative;
  margin: 0 0 30px 0;
  padding-left: 55px;
  font-size: 2.2rem;
  line-height: 1.3;
  @media #{$scr_sp} {
    margin: 0 0 20px 0;
    font-size: 1.8rem;
  }
  .detail & {
    font-size: 2.0rem;
    @media #{$scr_sp} {
      font-size: 1.8rem;
    }
  }
  .lease & {
    margin-top: 60px;
    color:$color_light_blue2;
    @media #{$scr_sp} {
      margin-top: 40px;
    }
  }
  .rent_flow & {
    margin-top: 80px;
    @media #{$scr_sp} {
      margin-top: 40px;
    }
  }
  &:before , &:after {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    background: #fff;
    .detail & {
      background: $color_blue;
    }
    .lease & {
      background: $color_light_blue2;
    }
    .rent_flow & {
      background: $color_blue;
    }
  }
  &:before {
    top:8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    @media #{$scr_sp} {
      top:6px;
    }
  }
  &:after {
    top:13px;
    width: 50px;
    height: 1px;
    @media #{$scr_sp} {
      top:11px;
    }
  }
}

/* ---------------------------------------------------------
記事一覧
----------------------------------------------------------*/
.post_ul {
  display: flex;
  flex-wrap: wrap;
  .post_ul_li {
    position: relative;
    width: 220px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    overflow: hidden;
    @media #{$scr_sp} {
      width: calc(50% - 2.5%);
      margin-bottom: 5%;
    }
    @media #{$scr_pc} {
      .reform &,
      .post-type-archive-reform_property & {
        width: 306px;
      }
    }
    &:hover {
      &:after {
        left:0;
      }
    }
    &:after {
      content: "";
      position: absolute;
      left:0;
      left:-180px;
      bottom:0;
      display: block;
      width:219.88px;
      height: 7.13px;
      background-image: url("../img/common_line.png");
      background-position: center;
      background-size: cover;
      transition:all ease .5s;
    }
    &:not(:nth-child(2n+2)){
      @media #{$scr_sp} {
        margin-right: 5%;
      }
    }
    &:not(:nth-child(4n+4)){
      @media #{$scr_pc} {
        margin-right: 40px;
        .reform &,
        .post-type-archive-reform_property & {
          margin-right: 0;
        }
      }
    }
    &:not(:nth-child(3n+3)){
      @media #{$scr_pc} {
        .reform &,
        .post-type-archive-reform_property & {
          margin-right: 40px;
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
  .post_ul_img {
    margin-bottom: 10px;
    a {
      display: block;
      overflow: hidden;
      &:hover {
        img {
          transform:scale(1.1);
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
      transition:ease all .5s;
    }
  }
  .post_ul_time {
    font-size: 1.2rem;
    color:$color_text_light02;
  }
  .post_ul_title {
    margin: 10px 0;
    font-size: 1.4rem;
    line-height: 1.3;
    font-weight: normal;
    .reform &,
    .post-type-archive-reform_property & {
      @media #{$scr_pc} {
        margin-top: 20px;
        font-size: 1.6rem;
      }
      @media #{$scr_sp} {
        margin-top: 15px;
      }
    }
    .is_home & {
      @media #{$scr_pc} {
        margin-top: 15px;
      }
    }
    a {
      color: #fff;
      &:hover {
        color: $color_link_text;
      }
    }
  }
  .post-categories {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    @media #{$scr_sp} {
      font-size: 1.0rem;
    }
    li {
      margin-right: 10px;
      line-height: 1.3;
    }
    a {
      color:$color_text_light02;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .post_ul_author {
    margin-top: 15px;
    font-size: 1.2rem;
    a {
      display: flex;
      align-items: center;
      color: $color_text_light02;
      &:hover {
        .name {
          color:$color_link_text;
        }
      }
    }
    .img {
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
    .name {
      margin-left: 10px;
    }
  }
  .post_ul_txt {
    .reform &,
    .post-type-archive-reform_property & {
      display: -webkit-box; /* 必須 */
      -webkit-box-orient: vertical; /* 必須 */
      -webkit-line-clamp: 3; /* 行数を制限 */
      overflow: hidden; /* はみ出た部分を非表示 */
      font-size: 1.4rem;
      @media #{$scr_sp} {
        font-size: 1.2rem;
      }
    }
  }
  .addtoany_share_save_container {
    display: none !important;
  }
}
/* ---------------------------------------------------------
ボタンwrapper
----------------------------------------------------------*/
.btn_wrapper {
  text-align: center;
}
/* ---------------------------------------------------------
ボタン
----------------------------------------------------------*/
.btn {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: $color_blue;
  color: #fff;
  border-radius: 28px;
  text-align: center;
  text-decoration: none;
  box-sizing:border-box;
  transition: ease all .5s;
  @media #{$scr_pc} {
    max-width: 240px;
  }
  .is_home & {
    @media #{$scr_sp} {
      width: auto;
      padding: 0 40px;
    }
  }
  &:hover {
    background: #fff;
    color: $color_blue;
    transition: ease all .5s;
    &:after {
      background-image: url("../img/common_icon_arrow_right_bl.png");
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 20px;
    top:50%;
    transform: translateY(-50%);
    width: 5.6px;
    // width: 5.06px;
    height: 10.12px;
    background-image: url("../img/common_icon_arrow_right.png");
    background-size: cover;
  }
}
.btn_wrap {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 20px;
    top:50%;
    transform: translateY(-50%);
    // width: 5.6px;
    width: 5.06px;
    height: 10.12px;
    background-image: url("../img/common_icon_arrow_right.png");
    background-size: cover;
  }
  &:hover {
    &:after {
      background-image: url("../img/common_icon_arrow_right_bl.png");
    }
  }
  .btn {
    &:after {
      display: none;
    }
  }
}
.form_btnarea {
  .btn {
    min-width: 240px;
    padding: 0  20px;
    border:none;
  }
  .btn_back {
    &:hover {
      input {
        background: #fff;
      }
    }
    &:after {
      left:20px;
      right: auto;
      transform: scale(-1, 1) translateY(-50%);
      .assess & {
        display: none;
      }
    }
    input {
      background: $color_bg_g;
    }
  }
}
.btn_min {
  max-width: none !important;
  width: auto;
  height: auto ;
  line-height: 1.3;
  padding: 10px 30px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
  &:hover {
    background: $color_blue;
    color: #fff;
    border: 1px solid $color_blue;
    &:after {
      background-image: url("../img/common_icon_arrow_right.png");
    }
  }
  &:after {
    right: 15px;
  }
}
/* ---------------------------------------------------------
サブナビ
----------------------------------------------------------*/
.subnavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  .subnavi_blog & {
    justify-content: flex-start;
  }
  li {
    margin: 0 10px 20px;
    .subnavi_blog & {
      margin: 0 20px 20px 0;
    }
    @media #{$scr_sp} {
      margin: 0 5px 10px;
      .subnavi_blog & {
        margin: 0 10px 10px 0;
      }
    }
  }
  a {
    box-sizing:border-box;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    padding: 0 20px;
    display: block;
    color: #fff;
    text-decoration: none;
    border:1px solid #fff;
    &:after {
      content: "";
      display: inline-block;
      width: 5.9px;
      // width: 5.06px;
      height: 10.12px;
      margin-left: 5px;
      background-image: url("../img/common_icon_arrow_right.png");
      background-size: cover;
    }
    &:hover , &.active {
      background: #fff;
      color:$color_black;
      transition: ease all .5s;
      &:after {
        background-image: url("../img/common_icon_arrow_right_b.png");
      }
    }
  }
}
/* ---------------------------------------------------------
ページ冒頭
----------------------------------------------------------*/
.boutou {
  margin-bottom: 50px;
  text-align: center;
  @media #{$scr_sp} {
    margin-bottom: 40px;
  }
}
/* ---------------------------------------------------------
セクション
----------------------------------------------------------*/
.section {
  line-height: 1.8;
}
/* ---------------------------------------------------------
セクション 冒頭
----------------------------------------------------------*/
.section_boutou {

}
/* ---------------------------------------------------------
セクション 背景画像
----------------------------------------------------------*/
.section_bg {
  height: 50vh;
  margin-top: 100px;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-position: center;
  background-size: cover;
  background-image: url("../img/about_concept_v.jpg");
  // background-attachment: fixed;
  @media #{$scr_sp} {
    height: 30vh
  }
}
/* ---------------------------------------------------------
カテゴリー サブナビ
----------------------------------------------------------*/
.subnavi_cat {
  margin:0 0 50px 0;
  font-size: 1.4rem;
  font-weight: bold;
  @media #{$scr_sp} {
    margin-bottom: 40px;
    font-size: 1.2rem;
  }
  .voice & {
    margin-bottom: 30px;
    @media #{$scr_sp} {
      // margin-bottom: 30px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .knowledge & {
      @media #{$scr_sp} {
        justify-content: flex-start;
      }
    }
    .subnavi_blog & {
      justify-content: flex-start;
    }
    .voice & {
      @media #{$scr_sp} {
        justify-content: space-between;
      }
    }
    li {
      margin: 0 10px 20px;
      .knowledge & {
        @media #{$scr_sp} {
          margin: 0 10px 10px 0;
        }
      }
      .subnavi_blog & {
        margin: 0 20px 20px 0;
      }
      .voice & {
        @media #{$scr_sp} {
          width: calc(50% - 5px);
          margin: 0 0 10px 0;
        }
      }
      @media #{$scr_sp} {
        margin: 0 5px 10px;
        .subnavi_blog & {
          margin: 0 10px 10px 0;
        }
      }
    }
    a {
      box-sizing:border-box;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      padding: 0 20px;
      display: block;
      color: #fff;
      text-decoration: none;
      border:1px solid #fff;
      text-align: center;
      &:hover , &.active {
        background: #fff;
        color:$color_black;
      }
    }
  }
}
/* ---------------------------------------------------------
サブナビ アンカー
----------------------------------------------------------*/
.subnavi_anchor {
  ul {
    li {
      a {
        position: relative;
        padding-right: 30px;
        &:hover {
          &:after {
            background-image: url(../img/common_icon_arrow_right_b.png);
          }
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          margin-top: 1px;
          width: 5.06px;
          height: 10.12px;
          background-image: url(../img/common_icon_arrow_right.png);
          background-size: cover;
        }
      }
    }
  }
}
/* ---------------------------------------------------------
サブナビ ページ下部
----------------------------------------------------------*/
.foot_subnavi {
  margin-top: 20px;
  .about & {
    margin-top:60px;
  }
  .service & {
    margin-top:60px;
  }
  ul {
  display: flex;
  flex-wrap: wrap;
  }
  li {
    position: relative;
    margin-top: 40px;
    padding-bottom: 10px;
    overflow: hidden;
    @media #{$scr_pc} {
      width: 220px;
    }
    @media #{$scr_sp} {
      width: calc(50% - 2.5%);
      margin-top: 5%;
    }
    &:hover , &.active{
      &:after {
        left:0;
      }
      a {
        color: $color_link_text;
        img {
          transform:scale(1.1);
        }
      }
    }
    &:after {
      content: "";
      position: absolute;
      left:0;
      left:-180px;
      bottom:0;
      display: block;
      width:219.88px;
      height: 7.13px;
      background-image: url("../img/common_line.png");
      background-position: center;
      background-size: cover;
      transition:all ease .5s;
    }
    &:not(:nth-child(2n+2)){
      @media #{$scr_sp} {
        margin-right: 5%;
      }
    }
    &:not(:nth-child(4n+4)){
      @media #{$scr_pc} {
        margin-right: 40px;
      }
    }
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  figure {
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
      transition:ease all .5s;
    }
  }
  h2 {
    margin: 15px 0;
    text-align: center;
    font-weight: normal;
    span {
      display: block;
    }
    .en {
      font-family: $font-family-en;
      font-size: 1.6rem;
      @media #{$scr_sp} {
        font-size: 1.4rem;
      }
    }
    .ja {
      margin-top: 3px;
      font-size: 1.0rem;
    }
  }
}
/* ---------------------------------------------------------
表
----------------------------------------------------------*/
table {
  width: 100%;
  box-sizing:border-box;
  font-size: 1.4rem;
  border-top: 1px solid $color_border;
  border-left: 1px solid $color_border;
  th, td {
    padding: 15px 20px;
    border-right: 1px solid $color_border;
    border-bottom: 1px solid $color_border;
    box-sizing:border-box;
    @media #{$scr_sp} {
      padding: 0;
    }
  }
  th {
    background: $color_bg_b;
    @media #{$scr_pc} {
      width: 170px;
    }
  }
  td {
    // width: 100%;
  }
}
/* ---------------------------------------------------------
dl 表
----------------------------------------------------------*/
.dl_table {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  border-top: 1px solid $color_border;
  border-left: 1px solid $color_border;
  dt, dd {
    padding: 15px 20px;
    box-sizing:border-box;
    border-right: 1px solid $color_border;
    border-bottom: 1px solid $color_border;
    @media #{$scr_sp} {
      width: 100%;
      padding: 10px 5%;
    }
  }
  dt {
    background: $color_bg_b;
    @media #{$scr_pc} {
      width: 170px;
    }
  }
  dd {
    @media #{$scr_pc} {
      width: calc(100% - 170px);
    }
  }
}
/* ---------------------------------------------------------
dl 表 4列の場合
----------------------------------------------------------*/
.dl_table_clm4 {
  dd {
    @media #{$scr_pc} {
      width: calc(50% - 170px);
    }
  }
}
/* ---------------------------------------------------------
キャプションリスト
----------------------------------------------------------*/
.list_caption {
  font-size: 1.4rem;
  line-height: 1.5;
  @media #{$scr_sp} {
    font-size: 1.2rem;
  }
  li {
    text-indent: -1em;
    padding-left: 1em;
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }
}
/* ---------------------------------------------------------
フォーム関連
----------------------------------------------------------*/
select {
  color: $color_black;
}

/* ---------------------------------------------------------
イベント・お知らせ　記事一覧
----------------------------------------------------------*/
.event_ul {
  border-top: 1px solid $color_border;
  li {
    border-bottom: 1px solid $color_border;
  }
  a {
    position: relative;
    display: flex;
    padding: 20px 30px 20px 10px;
    text-decoration: none;
    color: #fff;
    @media #{$scr_sp} {
      flex-direction: column;
    }
    &:hover {
      &:after {
        transform: translateX(10px) translateY(-50%) ;
      }
      h2 {
        color: $color_link_text;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 10px;
      top:50%;
      transform: translateY(-50%);
      width: 6px;
      height: 12px;
      background-image: url("../img/common_icon_arrow_right.png");
      background-size: cover;
      transition:ease all .5s;
    }
  }
  time {
    margin-right: 20px;
    color: $color_text_light02;
    @media #{$scr_sp} {
      margin-bottom: 5px;
      font-size: 1.4rem;
    }
  }
  h2 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.5;
  }
}
/* ---------------------------------------------------------
フロウリスト
----------------------------------------------------------*/
.list_step {
  padding-left: 40px;
  @media #{$scr_sp} {
    padding-left: 30px;
  }
  > li {
    position: relative;
    list-style-type: none;
    padding: 0 0 50px;
    @media #{$scr_sp} {
      padding-bottom: 40px;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left:-40px;
      top:0;
      width: 1px;
      height: 100%;
      background: $color_blue;
      @media #{$scr_sp} {
        left:-30px;
      }
    }
    &:last-child {
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
  }
}
.step_title {
  margin: 0 0 20px;
  padding: 7px 0 0 0;
  font-size: 2.6rem;
  line-height: 1.5;
  @media #{$scr_sp} {
    margin: 0 0 15px;
    padding-top: 7px;
    font-size: 1.8rem;
  }
  span {
    position: absolute;
    left:-40px - 25px;
    top:0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: $color_blue;
    color:#fff;
    font-size: 1.8rem;
    border-radius: 50%;
    @media #{$scr_sp} {
      left:-50px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 1.6rem;
    }
  }
}
.step_textarea {
  @media #{$scr_sp} {
    font-size: 1.2rem;
  }
  p {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .tel {
    line-height: 1.3;
    a {
      text-decoration: none;
      color:#fff;
      font-size: 2.3rem;
      color: $color_light_blue2;
      @media #{$scr_sp} {
        font-size: 2.0rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    span {
      display: block;
      font-size: 1.4rem;
      @media #{$scr_sp} {
        font-size: 1.0rem;
      }
    }
  }
}
/* ---------------------------------------------------------
キャプション
----------------------------------------------------------*/
.caption {
  text-indent: -1em;
  padding-left: 1em;
  font-size: 1.4rem;
  @media #{$scr_sp} {
    font-size: 1.2rem;
  }
}
/* ---------------------------------------------------------
リスト
----------------------------------------------------------*/
.list {
  list-style: disc;
  margin-left: 1em;
}