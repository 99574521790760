@charset "utf-8";
/* ---------------------------------------------------------
   page.css
----------------------------------------------------------*/
@media #{$scr_sp} {

}
@media #{$scr_pc} {

}
@media #{$scr_retina} {

}
// transform: translateX(-50%);
/* ---------------------------------------------------------
pagenavi
----------------------------------------------------------*/
.pagenavi {
  margin-top: 60px;
  text-align: center;
  @media #{$scr_sp} {
    margin-top: 10%;
  }
  .wp-pagenavi {
    a , span {
      display: inline-block;
      margin: 0 2.5px 10px 2.5px;
      padding: 8px 10px;
      color:$color_text_light02;
      text-decoration: none;
      border:1px solid $color_border;
    }
    a:hover , .current{
      background: #fff;
      color:$color_black;
    }
  }
}
/* ---------------------------------------------------------
投稿ページ
----------------------------------------------------------*/
.post_wrapper {
  @media #{$scr_pc} {
    display: flex;
    justify-content: space-between;
  }
}
/* ---------------------------------------------------------
投稿ページ　メインエリア
----------------------------------------------------------*/
.post_main {
  @media #{$scr_pc} {
    width: 700px;
  }
}
.post_header {
  img {
    max-width: 100%;
    height: auto;
  }
  time , .knowledge_oyacate {
    font-size: 1.2rem;
    color: $color_text_light02;
  }
  h1 {
    margin: 10px 0;
    font-size: 2.4rem;
    @media #{$scr_sp} {
      font-size: 2.2rem;
    }
  }
  .post-categories,
  .header_category {
    display: flex;
    font-size: 1.2rem;
    li {
      margin-right: 10px;
    }
  }
  .header_author {
    margin-top: 20px;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 1.4rem;
      text-decoration: none;
      &:hover {
        .name {
          text-decoration: underline;
        }
      }
    }
    .img {
      display: block;
      width: 45px;
      height: 45px;
      margin-right: 10px;
      overflow: hidden;
      border-radius: 50%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .header_catch {
    margin-top: 50px;
    @media #{$scr_sp} {
      margin-top: 40px;
    }
  }
}
.post_block {
  margin-top: 50px;
  @media #{$scr_sp} {
    margin-top: 40px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  p , figure , h2 , h3 , h4 , h5 , h6 , ul , ol , table , blockquote{
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 1.8;
    @media #{$scr_sp} {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  h2 {
    margin: 100px 0 45px;
    padding: 30px 0 0 0;
    font-size: 2.2rem;
    border-top: 2px solid $color_blue;
    line-height: 1.3;
    @media #{$scr_sp} {
      margin: 80px 0 40px;
    }
  }
  h3 {
    position: relative;
    margin: 100px 0 40px;
    padding-left: 15px;
    font-size: 1.8rem;
    line-height: 1.3;
    @media #{$scr_sp} {
      margin: 80px 0 40px;
    }
    &:before {
      position: absolute;
      left: 0;
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background: $color_blue;
    }
  }
  h4 {
    padding: 10px 15px;
    line-height: 1.3;
    font-size: 1.6rem;
    border: 1px solid $color_border;
  }
  figure {
    figcaption {
      margin-top: 10px;
      text-align: center;
      font-size: 1.4rem;
    }
  }
  a {
    // text-decoration: underline;
    // color: $color_key;
    &:hover {
      text-decoration: none;
    }
    &[target="_blank"] {
      &:after {
        display: inline-block;
        content: "";
        width: 11px;
        height: 9px;
        margin-left: 5px;
        margin-right: 5px;
        background-image: url("../img/common_icon_newtab.png");
        background-size: 100%;
      }
    }
    &[href$=".pdf"] {
      &:after {
        display: inline-block;
        content: "";
        width: 8px;
        height: 10px;
        margin-left: 5px;
        margin-right: 5px;
        background-image: url("../img/common_icon_file.png");
        background-size: 100%;
      }
    }
  }
  .a2a_kit {
    a {
      &[target="_blank"] {
        &:after {
          display: none;
        }
      }
    }
  }
  .wp-block-embed__wrapper{
    position:relative;
    width:100%;
    height:0;
    padding-top:56.25%;/* 高さと幅の比を16:9に固定。9/16*100=56.25 */
    iframe{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      border: 0;
    }
  }
  ul {
    li {
      position: relative;
      padding-left: 15px;
      &:not(:last-child){
        margin-bottom: 0.5em;
      }
       &:before {
        position: absolute;
        left:0;
        top:10px;
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background: $color_blue;
        border-radius: 50%;
      }
    }
  }
  ol {
    margin-left: 20px;
    li {
      &:not(:last-child){
        margin-bottom: 0.5em;
      }
    }
  }
  .wp-block-quote {
    margin: 0 0 1em 0;
    padding: 0 0 0 2em;
    border-left :3px solid $color_blue;
    @media #{$scr_sp} {
      padding: 0 0 0 1em;
    }
    p {
    }
    cite {
      a {
      }
    }
  }
  .wp-block-pullquote {
    text-align: center;
    padding: 0;
    border-top :3px solid $color_blue;
    border-bottom :3px solid $color_blue;
    blockquote {
      margin: 0;
      padding: 40px 20px;
      @media #{$scr_sp} {
        padding: 30px 5%;
      }
    }
    p {
      margin: 0;
      font-size: 2.4rem;
      @media #{$scr_sp} {
        // margin: 0 0 5px 0;
        font-size: 2.0rem;
      }
    }
    cite {
      font-size: 1.4rem;
      @media #{$scr_sp} {
        font-size: 1.4rem;
      }
      // margin-top: 40px;
    }
  }
  table {
    width: 100%;
    border-top: 1px solid $color_border;
    border-left: 1px solid $color_border;
    font-size: 1.4rem;
    td , th {
      border-right: 1px solid $color_border;
      border-bottom: 1px solid $color_border;
      padding: 15px ;
      @media #{$scr_sp} {
        padding: 10px ;
      }
    }
    thead td , th{
      font-weight: bold;
      text-align: center;
    }
  }
  .wp-block-table {
    margin-bottom: 50px;
    @media #{$scr_sp} {
      margin-bottom: 40px;
    }
    table {
      margin-bottom: 0;
    }
  }
  .wp_social_bookmarking_light {
    padding: 20px;
    background: #fff;
    color: $color_black;
    p {
      margin: 0;
    }
  }
  .wp-block-buttons,
  .addtoany_content {
    margin-top: 50px;
    @media #{$scr_sp} {
      margin-top: 40px;
    }
  }
}
.post_prevnext {
  display: flex;
  justify-content: space-between;
  margin-top:50px;
  font-size: 1.4rem;
  @media #{$scr_sp} {
    margin-top: 40px;
    font-size: 1.2rem;
  }
  li {
  }
  .prev a , .index a {
    padding-left: 10px;
    &:before {
      left: 0;
      transform:scale(-1, 1);
    }
  }
  .next a {
    padding-right: 10px;
    &:before {
      right: 0;
    }
  }
  a {
    position: relative;
    color:#fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top:2px;
      width:5.06px;
      height: 10.12px;
      background-image: url("../img/common_icon_arrow_right.png");
      background-size: cover;
      background-position: center;
    }
  }
}
/* ---------------------------------------------------------
投稿ページ　サイド
----------------------------------------------------------*/
.post_side {
  @media #{$scr_pc} {
    width: 260px;
  }
  @media #{$scr_sp} {
    margin-top: 80px;
  }
  h2 {
    margin: 0 0 20px 0;
    font-size: 1.6rem;
    &:not(:first-child){
      margin: 50px 0 20px 0;
      @media #{$scr_sp} {
        margin: 40px 0 20px 0;
      }
    }
  }
}
.side_post_ul {
  border-top: 1px solid $color_border;
  li {
    border-bottom: 1px solid $color_border;
    a {
      display: block;
      padding: 10px 0 15px;
      text-decoration: none;
      &:hover {
        h3 {
          color: $color_link_text;
          text-decoration: underline;
        }
      }
      time {
        font-size: 1.2rem;
        color: $color_text_light02;
      }
      h3 {
        margin: 5px 0 0 0;
        font-weight: normal;
        font-size: 1.4rem;
        color: #fff;
      }
    }
  }
}
.side_category_ul {
  border-top: 1px solid $color_border;
  font-size: 1.4rem;
  > li {
    padding: 15px 0;
    border-bottom: 1px solid $color_border;
  }
  .children {
    margin-top: 10px;
    font-size: 1.2rem;
   li {
    position: relative;
    padding-left: 15px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left:3px;
      top:5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $color_blue;
    }
    &:not(:last-child){
      margin-bottom: 5px;
    }
   }
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color:$color_link_text;
      text-decoration: underline;
    }
  }
}
.side_btns {
  margin-top: 50px;
  @media #{$scr_sp} {
    margin-top: 40px;
  }
}
/* ---------------------------------------------------------
メンバー サブナビ
----------------------------------------------------------*/
.subnavi_author {
  margin-bottom: 20px;
  font-size: 1.4rem;
  @media #{$scr_sp} {
    font-size: 1.2rem;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  li {
    margin-right: 20px;
    margin-bottom: 20px;
    @media #{$scr_sp} {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
      .name {
        color: $color_light_blue;
      }
    }
  }
  .img {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .name {
    margin-left: 10px;
    color: #fff;
  }
}
/* ---------------------------------------------------------
ブログ サブナビ
----------------------------------------------------------*/
.subnavi_blog {
  nav {
    display: flex;
    align-items: flex-start;
    @media #{$scr_sp} {
      flex-direction: column;
    }
  }
}
.subnavi_ttl {
  width: 160px;
  margin: 6px 0 0 0;
  position: relative;
  font-size: 1.4rem;
  flex-shrink: 0;
  @media #{$scr_sp} {
    margin-bottom: 20px;
  }
  .subnavi_cat & {
    margin: 10px 0 0 0;
    @media #{$scr_sp} {
      margin: 10px 0 20px 0;
    }
  }
  &:after {
    position: absolute;
    right: 20px;
    top:50%;
    transform: translateY(-50%);
    content: "";
    width:50px ;
    height: 1px;
    background: $color_border;
  }
}
/* ---------------------------------------------------------
お客様の声
----------------------------------------------------------*/
.voice_block {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  border-top: 1px solid $color_border;
  @media #{$scr_sp} {
    flex-wrap: wrap;
    padding: 20px 0;
    // flex-direction: column;
  }
  &:last-child {
    border-bottom: 1px solid $color_border;
  }
  img {
    height: auto;
    width: 100%;
  }
  .btn {
    height: auto;
    line-height: 1;
    margin-top: 20px;
    padding: 10px;
    font-size: 1.2rem;
    box-sizing:border-box;
    @media #{$scr_sp} {
      margin-top: 10px;
    }
    &:after {
      @media #{$scr_sp} {
        right: 10px;
      }
    }
  }
  .voice_left {
    @media #{$scr_pc} {
      width: 200px;
    }
    @media #{$scr_sp} {
      width: 47.5%;
      order: 2;
      // margin-bottom: 20px;
      // padding: 0 20%;
    }
  }
  .voice_center {
    @media #{$scr_pc} {
      width: 540px;
    }
    @media #{$scr_sp} {
       order: 1;
       margin-bottom: 20px;
    }
  }
  .voice_header {
    display: flex;
    margin-bottom: 20px;
    font-size: 1.4rem;
    .voice_cat , .voice_date {
      box-sizing:border-box;
      padding: 3px 6px;
    }
    .voice_cat {
      margin-right: 10px;
      background: #fff;
      color: $color_black;
    }
    .voice_date {
      color: $color_brown;
      border:1px solid $color_brown;
    }
  }
  .voice_table {
    display: flex;
    flex-wrap: wrap;
    border:1px solid $color_border;
    border-bottom: none;
    @media #{$scr_sp} {
      margin-bottom: 10px;
      font-size: 1.2rem;
    }
    dt , dd {
      padding: 13px 15px;
      border-bottom:1px solid $color_border;
      box-sizing:border-box;
      @media #{$scr_sp} {
        padding: 13px 10px;
      }
    }
    dt {
      width: 25%;
      text-align: center;
      border-right:1px solid $color_border;
      @media #{$scr_sp} {
        width: 30%;
      }
    }
    dd {
      width: 75%;
      @media #{$scr_sp} {
        width: 70%;
      }
    }
  }
  .voice_comment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    // margin-top: 20px;
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
    dt , dd {
      margin-top: 20px;
      box-sizing:border-box;
      line-height: 1.5;
      @media #{$scr_sp} {
        margin-top: 10px;
      }
    }
    dt {
      width: 25%;
      padding: 7px 0;
      font-weight: bold;
      @media #{$scr_sp} {
        width: 30%;
      }
    }
    dd {
      width: 75%;
      position: relative;
      padding: 7px 10px;
      background: $color_bg_g;
      @media #{$scr_sp} {
        width: 70%;
      }
      &:after {
        border: solid transparent;
        content:'';
        height:0;
        width:0;
        pointer-events:none;
        position:absolute;
        border-top-width:10px;
        border-bottom-width:10px;
        border-left-width:10px;
        border-right-width:10px;
        border-right-color:$color_bg_g;
        right:100%;
        top:10px;
        @media #{$scr_sp} {
          top:6px;
        }
      }
    }
  }
  .voice_right {
    @media #{$scr_pc} {
      width: 200px;
    }
    @media #{$scr_sp} {
      width: 47.5%;
      order: 3;
    }
  }
}
/* ---------------------------------------------------------
冒頭あいさつ
----------------------------------------------------------*/
.boutou {
  margin-bottom: 60px;
  text-align: center;
  line-height: 2;
  @media #{$scr_sp} {
    margin-bottom: 40px;
  }
  .assess & {
    margin-bottom: 30px;
  }
}
/* ---------------------------------------------------------
プレミア査定
----------------------------------------------------------*/
.mw_wp_form {
  .error {
    color: $color_red !important;
  }
}
.assess,
.kakunin,
.thanks {
  .main {
    line-height: 2;
    overflow-x:hidden;
    padding-bottom: 0;
  }
  .btn_wrapper {
    margin-top: 40px;
    .btn {
      max-width: 320px;
    }
  }
}
.kakunin,
.thanks {
  .form_btnarea {
    margin-bottom: 100px;
  }
}
.assess_bg {
  width: 100%;
  height: 384px;
  background-size: cover;
  background-position: center;
  background-image: url("../img/assess_bg01.jpg");
  @media #{$scr_sp} {
    height: 160px;
  }
  #anchor02 & {
    background-image: url("../img/assess_bg02.jpg");
  }
  #anchor03 & {
    background-image: url("../img/assess_bg03.jpg");
  }
}
.assess_section {
  position: relative;
  padding-bottom: 100px;
  @media #{$scr_sp} {
    padding-bottom: 60px;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top:0;
    z-index: -1;
    display: block;
    width: 25%;
    height: 100%;
    background: #181819;
  }
  &#anchor02 {
    &:after {
      right: auto;
      left: 0;
    }
  }
  &#anchor03 {
    // padding-bottom: 0;
  }
}
.assess_title {
  margin: 80px 0;
  text-align: center;
  @media #{$scr_sp} {
    margin: 40px 0;
    line-height: 1.3;
  }
  .en , .ja {
    display: block;
    font-weight: normal;
  }
  .ja {
    font-family: $font-family-yumincho;
    font-size: 3.6rem;
    @media #{$scr_sp} {
      font-size: 2.6rem;
    }
  }
  .en {
    margin-top: 0px;
    font-family: $font-family-en;
    font-size: 1.4rem;
    @media #{$scr_sp} {
      margin-top: 10px;
      font-size: 1.0rem;
    }
  }
}
.assess_bar {
  position: relative;
  padding: 35px 40px;
  background: rgba($color_light_blue,0.6);
  @media #{$scr_sp} {
    padding: 25px 5%;
  }
  &:after {
    content: "";
    width: 50vw;
    height: 100%;
    position: absolute;
    top:0;
    left: 100%;
    background: rgba($color_light_blue,0.6);
  }
}
.assess_bar02 {
  margin-top: 60px;
  background: rgba($color_light_blue,0.3);
  @media #{$scr_sp} {
    margin-top: 40px;
  }
  &:after {
    left: auto;
    right: 100%;
    background: rgba($color_light_blue,0.3);
  }
}
.assess_about_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.4rem;
  @media #{$scr_sp} {
    flex-direction: column;
  }
  li {
    box-sizing:border-box;
    margin-top: 40px;
    padding: 30px;
    background-position: center;
    background-size: cover;
    background-image:url("../img/assess_chigai01.jpg");
    @media #{$scr_retina} {
      background-image:url("../img/assess_chigai01@2x.jpg");
    }
    @media #{$scr_sp} {
      margin-top: 30px;
      padding: 5%;
    }
    &:first-child {
      @media #{$scr_pc} {
        width: 100%;
        padding-left: 100px;
      }
      dt {
        @media #{$scr_pc} {
          padding-top: 0;
          text-align: left;
        }
        span {
          @media #{$scr_pc} {
            left:auto;
            right:calc(100% + 20px);
            top:-5px;
            transform: none;
          }
        }
      }
    }
    &:not(:first-child){
      @media #{$scr_pc} {
        width: calc(50% - 20px);
      }
    }
    &:nth-child(2) {
      background-image:url("../img/assess_chigai02.jpg");
      @media #{$scr_retina} {
        background-image:url("../img/assess_chigai02@2x.jpg");
      }
    }
    &:nth-child(3) {
      background-image:url("../img/assess_chigai03.jpg");
      @media #{$scr_retina} {
        background-image:url("../img/assess_chigai03@2x.jpg");
      }
    }
    dt {
      margin-bottom: 10px;
      padding-top: 70px;
      text-align: center;
      position: relative;
      font-size: 1.8rem;
      font-weight: bold;
      @media #{$scr_sp} {
        font-size: 1.6rem;
      }
      span {
        left:50%;
        transform: translateX(-50%);
        position: absolute;
        top:0;
        display: block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        background:$color_blue;
        font-size: 3.8rem;
        text-align: center;
      }
    }
    dd {
      color: $color_text_light01;
      @media #{$scr_sp} {
        font-size: 1.2rem;
      }
    }
  }
}
.step_list {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  @media #{$scr_sp} {
    flex-direction: column;
  }
  > li {
    @media #{$scr_pc} {
      width: 297px;
    }
    &:not(:last-child){
      @media #{$scr_sp} {
        margin-bottom: 30px;
      }
    }
  }
  img {
    width: 100%;
  }
  figcaption {
    line-height: 1.5;
    h3 {
      margin: 15px 0 10px;
      font-size: 1.6rem;
      @media #{$scr_sp} {
        font-size: 1.4rem;
      }
    }
    p {
      font-size: 1.4rem;
      color: $color_text_light01;
      @media #{$scr_sp} {
        font-size: 1.2rem;
      }
    }
  }
}
.step_list_2 {
  li {
    @media #{$scr_pc} {
      width: 460px;
    }
  }
  figcaption {
    h3 {
      @media #{$scr_pc} {
        margin:  20px 0 15px;
      }
    }
  }
  .step_list_movie , .step_list_sns {
    margin-top: 20px;
    @media #{$scr_sp} {
      margin-top: 15px;
    }
  }
  .step_list_movie {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    li {
      text-align: center;
      @media #{$scr_pc} {
        width: 215px;
      }
      @media #{$scr_sp} {
        width: calc(50% - 5px);
      }
      a {
        text-decoration: none;
        color: #fff;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    figcaption {
      margin-top: 5px;
      color: $color_text_light01;
      @media #{$scr_sp} {
        font-size: 1.0rem;
      }
    }
  }
}
.step_list_report {
  @include clearfix;
  margin-top: 2em;
  font-size: 1.3rem;
  letter-spacing: 0;
  @media #{$scr_sp} {
    margin-bottom: -15px;
  }
  li {
    float: left;
    margin: 0 20px 15px 0;
    text-align: center;
    &:first-child {
      @media #{$scr_sp} {
        width: 100%;
        margin-right: 0;
      }
    }
    &:not(:first-child){
      @media #{$scr_pc} {
        width: 120px;
      }
      @media #{$scr_sp} {
        width: calc(50% - 10px);
      }
    }
    &:nth-child(6), &:nth-child(11){
      @media #{$scr_pc} {
        margin-right: 0;
      }
    }
    &:nth-child(2n+3){
      @media #{$scr_sp} {
        margin-right: 0;
      }
    }
  }
  figure {
    img {
      width: 100%;
    }
    figcaption {
      margin-top: 2px;
      @media #{$scr_sp} {
        font-size: 1.0rem;
      }
    }
  }
}
.assess_form_step {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 1.3;
  li {
    position: relative;
    margin: 0 15px;
    padding: 15px 10px;
    text-align: center;
    border:1px solid $color_border;
    box-sizing:border-box;
    @media #{$scr_pc} {
      width: 180px;
    }
    @media #{$scr_sp} {
      width: 100%;
      margin: 0;
      padding: 10px;
    }
    &.active {
      background: #fff;
      color: $color_black;
    }
    &:not(:last-child){
      &:after {
        position: absolute;
        content: "";
        left:calc(100% + 15px);
        top:50%;
        transform: translateY(-50%);
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent #ffffff;
        @media #{$scr_sp} {
          display: none;
        }
      }
    }
    &:nth-child(2){
      @media #{$scr_sp} {
        border-left: none;
        border-right: none;
      }
    }
  }
  span {
    display: block;
  }
  .en {
    font-size: 1.0rem;
  }
  .ja {
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
  }
}
.assess_form_textarea {
  margin: 50px 0;
  text-align: center;
  @media #{$scr_sp} {
    margin: 30px 0;
    text-align: left;
    font-size: 1.2rem;
  }
  p {
    &:not(:last-child){
      margin-bottom: 1em;
    }
    .hissu {
      margin-right: 3px;
    }
  }
}
.hissu {
  display: inline-block;
  margin-right: 5px;
  padding: 3px;
  background: $color_red;
  font-size: 1.2rem;
  line-height: 1;
}
.assess_form_form {
  h3 {
    margin: 30px 0 0 0;
    padding: 15px 20px;
    font-size: 1.8rem;
    border-top: 1px solid #fff;
    @media #{$scr_sp} {
      padding: 10px 2.5%;
    }
  }
}
.form_dl {
  display: flex;
  flex-wrap: wrap;
  @media #{$scr_sp} {
    flex-direction: column;
  }
  dt , dd {
    padding: 15px 20px;
    border-top: 1px solid $color_border;
    box-sizing:border-box;
    @media #{$scr_sp} {
      padding: 10px 2.5%;
    }
  }
  dt {
    font-weight: bold;
    @media #{$scr_pc} {
      width: 25%;
    }
    @media #{$scr_sp} {

    }
  }
  dd {
    @media #{$scr_pc} {
      width: 75%;
      padding-left: 0;
    }
    @media #{$scr_sp} {
      padding-bottom: 20px;
    }
    p {
      line-height: 1.3;
      &:not(:last-child){
        margin-bottom: 10px;
      }
      .caption {
        margin-left: 10px;
        font-size: 1.2rem;
      }
    }
  }
  .mwform-radio-field {
    margin-right: 10px;
  }
  .mwform-checkbox-field {
  }
  .p_jikan {
    @media #{$scr_sp} {
      font-size: 1.2rem;
    }
  }
  input {
    &[type="text"], &[type="email"], &[type="tel"] {
      color: #333;
      width: 100%;
      min-height: 30px;
    }
  }
  #zip ,#zip2 {
    width: auto;
  }
  textarea {
    color: #333;
    width: 100%;
  }
}
.form_doui {
  padding: 20px 0; 
  text-align: center;
  border-top: 1px solid $color_border;
  border-bottom: 1px solid $color_border;
  line-height: 1.5;
  p {
    margin-top: 5px;
  }
}
.form_btnarea {
  display: flex;
  justify-content: center;
  padding: 50px 0 0 0;
  @media #{$scr_sp} {
    margin-bottom: -20px;
    padding: 40px 0  0;
    flex-direction: column-reverse;
  }
  .btn_wrap {
    margin: 0 10px;
    @media #{$scr_sp} { 
      margin-bottom: 20px;}
  }
}
.mw_wp_form_complete{
  margin: 60px auto;
  text-align: center;
}

/* ---------------------------------------------------------
20211202 お客様の声改修
----------------------------------------------------------*/
.voice {
  .boutou {
    text-align: left;
  }
  .subnavi_voice {
    display: flex;
    .subnavi_voice_ttl {
      flex-shrink: 0;
      margin-top: 10px;
      @media #{$scr_sp} {
        margin-top: 5px;
      }
    }
    ul {
      @media #{$scr_sp} {
        justify-content: start;
      }
      li {
        margin: 0;
        margin-bottom: 10px;
        margin-right: 10px;
        @media #{$scr_sp} {
          width: auto;
        }
        &:not(:first-child){
          // margin-left: 10px;
        }
        a {
          @media #{$scr_sp} {
            line-height: 1.3;
            height: auto;
            padding: 5px 10px;
          }
        }
      }
    }
  }
  .subnavi_okyakusama {
    margin-bottom: 0;
  }
}

/* ---------------------------------------------------------
20240805 リフォーム関連制作
----------------------------------------------------------*/
.reform {
  &_boutou {
    .ttl {
      font-family: $font-family-yumincho;
      font-size: 3.6rem;
      font-weight: 400;
      line-height:1.3;
      @media #{$scr_sp} {
        font-size: 2.6rem;
      }
    }
    .txts {
      @media #{$scr_sp} {
        font-size: 1.2rem;
      }
    }
  }
  &_point {
    &_list {
      display: flex;
      flex-wrap: wrap;
      @media #{$scr_pc} {
        gap:35px;
      }
      @media #{$scr_sp} {
        gap:40px 0;
      }
      li {
        @media #{$scr_pc} {
          width: 310px;
        }
      }
      .img {
        img {
          width: 100%;
          height: auto;
        }
      }
      .ttl {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .en {
          position: relative;
          display: inline-block;
          color: $color_light_blue2;
          font-family: $font-family-en;
          font-size: 1.4rem;
          @media #{$scr_sp} {
            font-size: 1.2rem;
          }
          &:before,
          &:after {
            content: "";
            display: block;
            width: 10px;
            height: 1px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: $color_light_blue2;
          }
          &:before {
            right: calc(100% + 5px);
          }
          &:after {
            left: calc(100% + 5px);
          }
        }
        .ja {
          display: block;
          margin-top: 5px;
          font-size: 1.6rem;
        }
      }
      .text {
        @media #{$scr_sp} {
          font-size: 1.2rem;
        }
      }
    }
  }
  .section_title {
    @media #{$scr_sp} {
      margin-top: 70px;
      margin-bottom: 40px;
    }
    .ja {
      @media #{$scr_sp} {
        font-size: 2.0rem;
      }
    }
    .sub {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      padding: 0 20px;
      background: $color_blue;
      color: #fff;
      font-size: 1.8rem;
      border-radius:20px;
      @media #{$scr_sp} {
        height: 35px;
        line-height: 35px;
        font-size: 1.2rem;
      }
    }
  }
  .line_title {
    @media #{$scr_sp} {
      padding-left: calc(30px + 10px);
      font-size: 1.6rem;
    }
    &:before,
    &:after {
      background: $color_light_blue2;
    }
    &:after {
      @media #{$scr_sp} {
        width: 30px;
      }
    }
    
  }
  &_ex {
    &_main {
      display: flex;
      @media #{$scr_sp} {
        flex-direction: column;
      }
      &_left {
        @media #{$scr_pc} {
          width: 654px;
        }
      }
      &_right {
        font-size: 1.5rem;
        @media #{$scr_pc} {
          width: 310px;
          margin-left: 35px;
        }
        @media #{$scr_sp} {
          margin-top: 10px;
          font-size: 1.2rem;
        }
        .data {
          @media #{$scr_sp} {
            display: flex;
            flex-wrap: wrap;
          }
          dt {
            color: $color_light_blue2;
            font-size: 1.2rem;
            @media #{$scr_sp} {
              width: 5.5em;
              margin-top: 10px;
              // font-size: 1.0rem;
            }
            &:not(:first-child) {
              @media #{$scr_pc} {
                margin-top: 20px;
              }
            }
          }
          dd {
            margin-top: 5px;
            @media #{$scr_sp} {
              width: calc(100% - 5.5em);
              margin-top: 10px;
            }
          }
        }
        .txts {
          margin-top: 30px;
          @media #{$scr_sp} {
            margin-top: 20px;
          }
          p {
            margin-top: 20px;
            @media #{$scr_sp} {
              margin-top: 10px;
            }
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    &_ba {
      display: flex;
      flex-wrap: wrap;
      @media #{$scr_pc} {
        gap:80px 40px;
        margin-top: 80px;
      }
      @media #{$scr_sp} {
        gap:50px 0;
        margin-top: 50px;
      }
      &_block {
        @media #{$scr_pc} {
          width: 480px;
        }
        .imgs {
          img {
            width: 100%;
            height: auto;
          }
        }
        .txt {
          margin-top: 20px;
          font-size: 1.2rem;
          @media #{$scr_pc} {
            font-size: 1.5rem;
            
          }
        }
      }
    }
  }
  .section_property {
    .btn_wrapper {
      @media #{$scr_sp} {
        margin-top: 20px;
      }
    }
    .btn {
      @media #{$scr_pc} {
        max-width: 400px;
      }
    }
  }
  &_contact {
    margin-top: 120px;
    padding: 50px 0;
    background: $color_bg_g;
    @media #{$scr_sp} {
      margin-top: 60px;
      padding: 5%;
    }
    .section_title {
      margin-top: 0;
      margin-bottom: 30px;
      @media #{$scr_sp} {
        margin-bottom: 20px;
      }
    }
    .txt {
      text-align: center;
      @media #{$scr_sp} {

      }
    }
    .btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
      font-size: 1.6rem;
      @media #{$scr_sp} {
        margin-top: 20px;
        font-size: 1.4rem;
      }
      li {
        margin: 0 10px;
        @media #{$scr_sp} {
          margin: 0 0 20px 0;
        }
        &:nth-child(1){
          a {
            &:hover {
              &:before {
                background-image: url("../img/common_icon_tel_b.png");
              }
            }
            &:before {
              vertical-align: -5px;
            }
          }
        }
        &:nth-child(2){
          a {
            &:hover {
              &:before {
                background-image: url("../img/common_icon_mail_b.png");
              }
            }
            &:before {
              width: 17px;
              height: 13px;
              background-image: url("../img/common_icon_mail.png");
              vertical-align: -8px;
              @media #{$scr_sp} {
                width: 13.45px;
                height: 8.95px;
                vertical-align: -5px;
              }
            }
          }
        }
        &:nth-child(3){
          a {
            &:hover {
              &:before {
                background-image: url("../img/common_icon_line_b.png");
              }
            }
            &:before {
              width: 18px;
              height: 16px;
              background-image: url("../img/common_icon_line.png");
              vertical-align: -9px;
              @media #{$scr_sp} {
                width: 15.43px;
                height: 13.67px;
              }
            }
          }
        }
      }
      a {
        position: relative;
        display: block;
        width: 270px;
        height: 56px;
        line-height: 56px;
        padding: 0 20px;
        background: $color_blue;
        color:#fff;
        border-radius: 28px;
        text-decoration: none;
        box-sizing:border-box;
        text-align: center;
        &:hover {
          background: #fff;
          color:$color_black;
        }
        &:before {
          transform: translateY(-50%);
          content: "";
          display: inline-block;
          width: 18px;
          height: 12px;
          background-image: url("../img/common_icon_tel.png");
          background-position: center;
          background-size: 100%;
          vertical-align: middle;
          margin-right: 5px;
          @media #{$scr_sp} {
            width: 15.36px;
            height: 9.54px;
          }
        }
      }
    }
  }
  &_comingsoon {
    text-align: center;
    @media #{$scr_pc} {
    }
  }
}
.exSwiper {
  &-main {
    .swiper-slide {
      img {
        width: 100%;
        height: auto;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background-image: url("../img/common_slide_arrow_right.png");
      background-size: contain;
      transition:ease all .3s;
      @media #{$scr_sp} {
        width: 30px;
        height: 30px;
      }
      &:hover {
        background-image: url("../img/common_slide_arrow_right_hover.png");
      }
      &:after {
        display: none;
      }
    }
    .swiper-button-prev {
      transform:scale(-1,1);
    }
  }
  &-thumbs {
    .swiper-slide {
      width: 80px;
      height: 80px;
      opacity:0.5;
      @media #{$scr_sp} {
        width: 65px;
        height: 65px;
      }
      &-thumb-active {
        opacity: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.twentytwenty {
  &-handle {
    background: rgba(#fff,.95);
    &:before,
    &:after {
      opacity: .95;
    }
    .twentytwenty-left-arrow {
      border-right:6px solid $color_blue;
    }
    .twentytwenty-right-arrow {
      border-left:6px solid $color_blue;
    }
  }
  &-before-label,
  &-after-label {
    &:before {
      top:10px !important;
      margin-top: 0 !important;
      text-transform: uppercase;
      line-height: 30px;
      padding: 0 10px;
      @media #{$scr_sp} {
        padding: 0 5px;
        line-height: 20px;
        font-size: 1.0rem;
      }
    }
  }
}

