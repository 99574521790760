@charset "utf-8";
///* @ clearfix
// ------------------------------------------------------------ */


@mixin clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

///* @ end */
